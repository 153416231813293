<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getContractItemsList()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-file-multiple-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Contract Items
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getCheckInsList()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-account-check-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Check Ins
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-btn
      color="red darken-3"
      class="ml-7 my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left-bold
      </v-icon>
      Back
    </v-btn>
    <div v-if="showContractDetails">
      <p class="project-header">
        Project Name:- <font
          class="project-name"
        >
          {{ projectContractDetails.project.name }}
        </font>
      </p>
      <div class="project-progress-header">
        <p class="project-progress-title">
          Contract Progress:-
          <v-progress-linear
            class="progress-bar-style"
            color="#37474F"
            height="20"
            striped
            rounded
            dark
            :value="projectContractDetails.contract_progress"
          >
            <template>
              <strong
                v-if="projectContractDetails.contract_progress !== null"
                class="percentage-style"
              >{{ Math.ceil(projectContractDetails.contract_progress) }}%</strong>
              <strong v-else>0%</strong>
            </template>
          </v-progress-linear>
        </p>
      </div>
      <v-row
        class="cost-items"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            class="contract-card"
            color="ECEFF1"
            shaped
            hover
          >
            <v-card-title />
            <p class="contract-content">
              Contract Name:- <font
                class="font-my-style"
              >
                {{ projectContractDetails.name }}
              </font>
            </p>
            <p class="contract-content">
              Supplier:- <font
                v-if="projectContractDetails.company !== null"
                class="font-my-style"
              >
                {{ projectContractDetails.company.name }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="contract-content">
              Main Contract:- <font
                v-if="projectContractDetails.main_contract !== null"
                class="font-my-style"
              >
                {{ projectContractDetails.main_contract.name }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="contract-content">
              Original Amount:- <font
                v-if="projectContractDetails.original_budget !== null"
                class="font-my-style"
              >
                <span class="currency-style">{{ projectContractDetails.project.currency }}</span> {{ projectContractDetails.original_budget }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            class="contract-date-card"
            color="ECEFF1"
            shaped
            hover
          >
            <v-card-title />
            <p class="contract-content">
              Start Date:- <font
                v-if="projectContractDetails.main_contract !== null"
                class="font-my-style"
              >
                <span v-if="projectContractDetails.main_contract.start_date">
                  {{ projectContractDetails.main_contract.start_date }}
                </span>
                <span v-else>N/A</span>
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="contract-content">
              Planned End Date:- <font
                v-if="projectContractDetails.main_contract !== null"
                class="font-my-style"
              >
                <span v-if="projectContractDetails.main_contract.planned_completion_date !== null">
                  {{ projectContractDetails.main_contract.planned_completion_date }}
                </span>
                <span
                  v-else
                  class="font-my-style"
                >N/A</span>
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="contract-content">
              Actual End Date:- <font
                v-if="projectContractDetails.main_contract !== null"
                class="font-my-style"
              >
                <span v-if="projectContractDetails.main_contract.actual_completion_date !== null">
                  {{ projectContractDetails.main_contract.actual_completion_date }}
                </span>
                <span
                  v-else
                  class="font-my-style"
                >N/A</span>
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="contract-content">
              Projected Work Hours:- <font
                v-if="projectContractDetails.total_quantity !== null"
                class="font-my-style"
              >
                {{ projectContractDetails.total_quantity }} Hrs
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            class="contract-cost-card"
            color="ECEFF1"
            shaped
            hover
          >
            <v-card-title />
            <p class="contract-content">
              Projected Budget:- <font
                v-if="projectContractDetails.projected_budget !== null"
                class="font-my-style"
              >
                <span class="currency-style">{{ projectContractDetails.project.currency }}</span> {{ projectContractDetails.projected_budget }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="contract-content">
              Projected Cost:- <font
                v-if="projectContractDetails.projected_cost !== null"
                class="font-my-style"
              >
                <span class="currency-style">{{ projectContractDetails.project.currency }}</span> {{ projectContractDetails.projected_cost }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="contract-content">
              Variance Total: <font
                v-if="projectContractDetails.variance_total !== null"
                :color="getDifferenceColor(projectContractDetails.variance_total)"
                class="difference-font"
              >
                <span class="currency-style">{{ projectContractDetails.project.currency }}</span> {{ projectContractDetails.variance_total }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="contract-content">
              Timesheet Hours:- <font
                v-if="projectContractDetails.time_sheet_hours !== null"
                class="font-my-style"
              >
                {{ projectContractDetails.time_sheet_hours }} Hrs
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br>
    <hr>
    <br>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  class="drawer-style"
                >
                  mdi-checkbox-multiple-blank-outline
                </v-icon>
              </v-list-item-icon>
              Contract Details
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getContractItemsList()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-file-multiple-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Contract Items
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getCheckInsList()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-account-check-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Check Ins
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <centre-spinner
      :loading="loading"
    />
    <contract-items-list v-if="showContractItemsList" />
    <contract-check-ins v-if="showContractCheckInsList" />
  </v-container>
</template>

<script>
import ContractItemsList from './ContractItemsList';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import ContractCheckIns from './ContractCheckIns.vue';

  export default {
    name: 'ContractSecondNavDrawer',
    components: {
      'contract-items-list': ContractItemsList,
      'centre-spinner': spinner,
      'contract-check-ins': ContractCheckIns,
    },
    data () {
      return {
        selectedItem: -1,
        showContractDetails: false,
        loading: false,
        showContractItemsList: true,
        showContractCheckInsList: false,
      };
    },
    computed: {
      projectContractDetails () {
        return this.$store.getters['projects/getProjectContractDetails'];
      },
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    async mounted () {
      this.selectedItem = 0;
      await this.getProjectContractDetails();
    },
    methods: {
      back () {
        this.$store.dispatch('projects/storeProjectSecondNavDrawerTab', {
          toTab: 5,
        });
        const projectId = this.$route.params.projectId;
        this.$router.push(`/projects/${projectId}/details`);
      },
      async getProjectContractDetails () {
        this.loading = true;
        const projectContractId = this.$route.params.contractId;
        await this.$store.dispatch('projects/fetchProjectContractDetails', {
          projectContractId: projectContractId,
        }).then(() => {
        this.showContractDetails = true;
        this.contractItemsList = this.projectContractDetails.budget_items;
        });
        this.loading = false;
        },
      getContractItemsList () {
        this.showContractItemsList = true;
        this.showContractCheckInsList = false;
      },
      getCheckInsList () {
        this.showContractItemsList = false;
        this.showContractCheckInsList = true;
      },
      getDifferenceColor (val) {
        if (val === 0) {
          return '#2E7D32';
        }
        return '#C62828';
      },
    },
  };
</script>

<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.my-back-btn-style {
  margin-bottom: -20px;
}
.project-progress-header{
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: -20px;
}
.project-progress-title{
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
  display: flex;
  gap: 20px;
}
.progress-bar-style{
  width: 40%;
  margin-top: 6px;
}
.percentage-style{
  font-size: 15px;
}
@media (max-width: 959px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
  .contract-card {
    margin-left: -20px;
  }
  .contract-date-card {
    margin-top: -20px;
    margin-left: -20px;
  }
  .contract-cost-card {
    margin-top: -20px;
    margin-left: -20px;
  }
  .project-header {
    margin-left: 20px;
  }
}
@media (min-width: 959px) and (max-width: 1245px) {
  .font-my-style {
  font-weight: bold;
  font-size: 12px !important;
  color: #37474F;
  }
  .contract-content {
  font-size: 12px !important;
  font-weight: bold;
  color: #546E7A;
  margin-left: 12px !important;
  line-height: 0.8;
  }
}
.drawer-style {
  font-size:25px;
  color:#37474F;
  font-weight:bold;
}
.drawer-item-style {
  font-size:14px !important;
  color:#37474F !important;
  font-weight:bold !important;
}
.contract-content {
  font-size: 14px;
  font-weight: bold;
  color: #546E7A;
  margin-left: 15px;
  line-height: 0.8;
}
.font-my-style {
  font-weight: bold;
  font-size: 14px;
  color: #37474F;
}
.difference-font {
  font-weight: bold;
  font-size: 14px;
}
.contract-card {
  background: #ECEFF1;
  border-bottom: 2px solid #CFD8DC !important;
  border-top: 8px solid #CFD8DC !important;
  padding-top: 15px;
  cursor: default;
}
.contract-cost-card {
  background: #ECEFF1;
  border-bottom: 2px solid #CFD8DC !important;
  border-top: 8px solid #CFD8DC !important;
  padding-top: 15px;
  cursor: default;
}
.contract-date-card {
  background: #ECEFF1;
  border-bottom: 2px solid #CFD8DC !important;
  border-top: 8px solid #CFD8DC !important;
  padding-top: 15px;
  cursor: default;
}
.project-header {
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: -20px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
}
.project-name {
  color: #FF3700;
}
.cost-items {
  margin-left: 25px;
}
.currency-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
}
</style>
